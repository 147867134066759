import React from "react";
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const TestimonialGridEntry = ({post}) => {
    const imageRes = post.featured_media.localFile.childImageSharp.fluid;

    return (
        <li className="testimonials-grid-entry">
            <Img fluid={imageRes} key={imageRes.src} />
        </li>
    );
};

TestimonialGridEntry.propTypes = {
	post: PropTypes.object.isRequired,
};

export default TestimonialGridEntry;
