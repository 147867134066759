import React from "react";
import { graphql } from "gatsby";
import get from 'lodash.get';

import Layout from '../components/layout';
import PageHeader from '../components/page-header';
import TestimonialsListEntry from '../components/testimonials-list-entry';
import TestimonialsGridEntry from '../components/testimonials-grid-entry';

const TestimonialsPage = ({data}) => {
    const testimonials = get(data, 'allWordpressWpClients.edges', []);

    const fullTestimonials = testimonials.filter(({node}) => node.content !== '');
    const minTestimonials  = testimonials.filter(({node}) => node.content === '');

    const pageTitle = get(data, 'site.siteMetadata.pageTitles.testimonials', '');
    const siteTitle = get(data, 'site.siteMetadata.title', '');

    return (
        <Layout>
            <PageHeader
                heading="Testimonials"
                subHeading="Our work… in the words of our clients"
                pageTitle={`${pageTitle} | ${siteTitle}`}
            />
            <div className="testimonials-list-component">
                <div className="component-wrapper">
                    {fullTestimonials.map(({node}) => (
                        <TestimonialsListEntry
                            key={node.id}
                            post={node}
                        />
                    ))}
                    <ul className="testimonials-grid">
                        {minTestimonials.map(({node}) => (
                            <TestimonialsGridEntry
                                key={node.id}
                                post={node}
                            />
                        ))}
                    </ul>
                </div>
            </div>
        </Layout>
    );
};

export default TestimonialsPage;

export const pageQuery = graphql`
    query TestimonialsQuery {
        site {
            siteMetadata {
                title
                pageTitles {
                    testimonials
                }
            }
        }
        allWordpressWpClients {
            edges {
                node {
                    id
                    content
                    acf {
                        testimonial_person_name
                        testimonial_person_position
                        work_done
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 400) {
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
            }
        }
    }`;
